@import '../../styles/custom';
@import '~bootstrap/scss/mixins';

.react-badge {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 4px 8px !important;
  border: 1px solid white;

  &--large {
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 4px 10px !important;
  }
}

.outline-primary {
  color: $dark !important;
  border: 1px solid $primary;
  background-color: rgba($color: $primary, $alpha: 0.1) !important;
}

.outline-secondary {
  color: $dark !important;
  border: 1px solid $secondary;
  background-color: rgba($color: $secondary, $alpha: 0.1) !important;
}

.outline-success {
  color: $dark !important;
  border: 1px solid $success;
  background-color: rgba($color: $success, $alpha: 0.1) !important;
}

.outline-warning {
  color: $dark !important;
  border: 1px solid $warning;
  background-color: rgba($color: $warning, $alpha: 0.1) !important;
}

.outline-danger {
  color: $dark !important;
  border: 1px solid $danger;
  background-color: rgba($color: $danger, $alpha: 0.1) !important;
}

.outline-info {
  color: $dark !important;
  border: 1px solid $info;
  background-color: rgba($color: $info, $alpha: 0.1) !important;
}

.confi-badge {
  color: $primary !important;
  font-size: 14px !important;
  background-color: $light !important;
  font-weight: normal !important;

  &__lg {
    font-size: 22px !important;
    color: $secondary !important;
    background-color: $light !important;
    font-weight: normal !important;
  }

  &__link {
    &:hover {
      background-color: #cce7f6 !important;
    }
  }
}
